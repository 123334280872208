<template>
  <v-container class="tw-py-4 md:tw-py-10">
    <v-col class="mx-auto" cols="12" md="12">
      <div class="tw-flex tw-justify-center">
        <img class="tw-w-20 md:tw-w-24" src="@/assets/header.png" alt="" />
      </div>
      <div class="tw-text-center">
        <h1 class="tw-py-2 md:tw-py-4 tw-text-2xl md:tw-text-3xl tw-font-bold">
          Welcome to APA Assessment Center
        </h1>
        <p class="tw-px-2 md:tw-px-12">
          You have been selected to bid on the vehicle below and
          <span class="c-blue-text tw-font-bold"
            >submit your report within the next 24 hours</span
          >
          via our channels; please proceed to read the special notes and repair
          guidelines before filling out the bid form.
        </p>
      </div>

      <template v-if="!loadingGarage && !loading">
        <v-card
          v-if="garageReportLoading"
          elevation="2"
          class="tw-py-5 md:tw-py-20 tw-mt-5 md:tw-mt-16"
        >
          <div class="tw-flex tw-justify-center tw-items-center tw-flex-col">
            <h2 class="tw-text-xl md:tw-text-3xl mb-4">
              Downloading Bid Report
            </h2>
            <v-progress-circular :size="50" color="primary" indeterminate />
          </div>
        </v-card>
        <template v-else>
          <v-card
            v-if="!formSubmitted"
            elevation="2"
            class="tw-px-3 md:tw-px-6 tw-mt-5 md:tw-mt-16"
          >
            <card-title icon="/images/icons/accident_icon.png">{{
              garage.name
            }}</card-title>
            <div class="tw-px-0 md:tw-px-6">
              <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
                Special notes
              </h2>
              <hr class="tw-mb-2 md:tw-mb-5" />
              <div>
                <p>
                  1. The estimate has been limited to work rendered necessary as
                  the direct result of the accident. <br /><br />
                  2. If during the course of repairs, it is found that any
                  increase over the estimate is required, further authority must
                  be obtained before proceeding.
                  <br /><br />
                  3. During the course of repairs, should it be found necessary
                  to fit replacement parts due to wear and tear and/or
                  mechanical breakdown, authority must be obtained directly from
                  the insured.
                  <br />
                  <br />
                  4. Copies of spares issue notes to accompany final invoice.
                  Failure to comply may prejudice payment. Vehicle to be
                  re-inspected before release.
                </p>
              </div>
              <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
                Repair Guidelines
              </h2>
              <hr class="tw-mb-2 md:tw-mb-5" />
              <p>
                Strip the vehicle as required. Remove and renew the listed
                items. Set adjust and spray paint the areas affected to match
                the original color code.
              </p>
              <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
                Damaged Vehicle Photos
              </h2>
              <hr class="tw-mb-2 md:tw-mb-5" />
              <p>
                Attach and submit the photos of the damaged vehicle to show
                where necessarily repairs should be done.
              </p>
              <images :photos="photos" />

              <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
                Client Details
              </h2>
              <hr class="tw-mb-2 md:tw-mb-5" />
              <div
                class="
                  tw-grid tw-grid-cols-1
                  md:tw-grid-cols-2
                  tw-gap-0 tw-gap-8
                "
              >
                <div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold"
                      >Assessment No.</label
                    >
                    <span class="c-gray-text">{{ booking.id }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Claim No.</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.claimNumber
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Insured Name</label>
                    <span class="c-gray-text">{{
                      booking.clientInfo.name
                    }}</span>
                  </div>
                </div>
                <div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Date Created</label>
                    <span class="c-gray-text">{{
                      booking.createdAt | formatToHuman
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Policy No.</label>
                    <span class="c-gray-text">{{
                      booking.policyInfo.policyNumber
                    }}</span>
                  </div>
                </div>
              </div>
              <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
                Vehicle Details
              </h2>
              <hr class="tw-mb-2 md:tw-mb-5" />
              <div
                class="
                  tw-grid tw-grid-cols-1
                  md:tw-grid-cols-3
                  tw-gap-0 tw-gap-8
                "
              >
                <div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold"
                      >Registration No.</label
                    >
                    <span class="c-gray-text">{{
                      booking.vehicleInfo.registrationNumber
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold"
                      >Vehicle Color</label
                    >
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.color
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Engine No.</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.engineNumber
                    }}</span>
                  </div>
                </div>
                <div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Make</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.make
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">YOM</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.yearOfManufacture
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Chassis No.</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.chassisNumber
                    }}</span>
                  </div>
                </div>
                <div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Model</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.model
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Vehicle Type</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.bodyType
                    }}</span>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                    <label class="c-gray-text tw-font-bold">Mileage</label>
                    <span class="c-gray-text">{{
                      booking.biddingTemplate.mileage
                    }}</span>
                  </div>
                </div>
              </div>
              <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
                Garage Bid Form
              </h2>
              <hr class="tw-mb-2 md:tw-mb-5" />
              <p>
                Submit your Bid on the Vehicle for confirmation of the estimated
                prices of vehicle parts before repairs and quantity required,
                grade and quality of the parts as well as their prices.
              </p>
              <v-form v-model="isValid">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th scope="col" class="text-left">Parts/Services</th>
                        <th scope="col" class="text-left">Type</th>
                        <th scope="col" class="text-left">Quantity</th>
                        <th scope="col" class="text-left">Unit Price</th>
                        <th scope="col" class="text-left">Total</th>
                        <th scope="col" class="text-left">VAT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in parts" :key="item.name">
                        <td>{{ item.part }}</td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>
                          <vuetify-money
                            v-model="item.unitPrice"
                            :rules="[required('Unit price')]"
                            :options="{ precision: 0 }"
                          ></vuetify-money>
                        </td>
                        <td>{{ getTotal(item) | formatCurrency }}</td>
                        <td>
                          <v-switch v-model="item.vat"></v-switch>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <hr />
                <div
                  class="
                    tw-flex tw-justify-start
                    md:tw-justify-end
                    tw-py-2
                    md:tw-py-4
                  "
                >
                  <div class="tw-w-full md:tw-w-1/3">
                    <div class="tw-grid tw-grid-cols-2 c-gray-text">
                      <h6>Net Total</h6>
                      <h6>Ksh. {{ netTotal | formatCurrency }}</h6>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 c-gray-text">
                      <h6>VAT 16%</h6>
                      <h6>Ksh. {{ vatTotal | formatCurrency }}</h6>
                    </div>
                    <div
                      class="
                        tw-grid tw-grid-cols-2
                        c-gray-text
                        tw-text-xl
                        md:tw-text-2xl
                      "
                    >
                      <h6>TOTAL</h6>
                      <h6>Ksh. {{ grandTotal | formatCurrency }}</h6>
                    </div>
                  </div>
                </div>
                <hr />
                <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
                  Additional Information
                </h2>
                <hr class="tw-mb-2 md:tw-mb-5" />
                <p v-html="booking.biddingTemplate.additionalInformation"></p>
                <div
                  class="
                    tw-grid tw-grid-cols-1
                    md:tw-grid-cols-2
                    tw-gap-2
                    md:tw-gap-10
                  "
                >
                  <div class="tw-mt-2 md:tw-mt-6">
                    <label>Assessor's Signature</label>
                    <v-text-field
                      placeholder="Full Name"
                      :rules="[required('Full Name')]"
                      disabled
                      :value="booking.biddingTemplate.assessorName"
                    ></v-text-field>
                  </div>
                  <div class="tw-mt-2 md:tw-mt-6">
                    <label class="c-blue-text">Repairer's Signature</label>
                    <v-text-field
                      placeholder="Full Name"
                      :rules="[required('Full Name')]"
                      disabled
                      :value="garage.contact_person"
                    ></v-text-field>
                  </div>
                </div>
              </v-form>
            </div>
            <div class="tw-flex tw-justify-center">
              <div
                class="
                  tw-w-2/3
                  md:tw-w-1/3
                  tw-py-4
                  md:tw-py-10
                  tw-space-x-2 tw-flex tw-justify-center
                "
              >
                <v-btn
                  color="warning"
                  :loading="submitting"
                  :disabled="!isValid"
                  @click="submitBooking"
                  >Submit</v-btn
                >
              </div>
            </div>
          </v-card>
          <v-card
            v-else
            elevation="2"
            class="tw-py-5 md:tw-py-20 tw-mt-5 md:tw-mt-16"
          >
            <div class="tw-flex tw-justify-center tw-items-center tw-flex-col">
              <v-icon size="100" color="success"
                >mdi-check-circle-outline</v-icon
              >
              <h2 class="tw-text-xl md:tw-text-3xl">
                Bid form submitted successfully
              </h2>
              <v-btn
                class="tw-mt-4 md:tw-mt-10"
                color="primary"
                :to="{ name: 'Dashboard' }"
                >Go Home</v-btn
              >
            </div>
          </v-card>
        </template>
      </template>
      <div v-else class="tw-w-full tw-flex tw-justify-center tw-py-16">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-col>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import { formatCurrency } from '@/utils/helpers'
import { VAT } from '@/utils/const'
import Validations from '@/utils/Validations'
import axios from 'axios'
import { formatToHuman } from '@/utils/time'
import Images from '@/components/Images'
import downloadReportsMixin from '@/mixins/downloadReportsMixin'

export default {
  name: 'GarageBidForm',
  components: { Images, CardTitle },
  mixins: [downloadReportsMixin],
  data(vm) {
    return {
      vatRate: VAT,
      ...Validations,
      loading: true,
      loadingGarage: true,
      booking: '',
      garage: '',
      bookingId: vm.$route.params.bookingId,
      garageId: vm.$route.params.garageId,
      submitting: false,
      isValid: false,
      formSubmitted: false,
    }
  },
  computed: {
    parts() {
      return this.scanObject(this.booking, 'biddingTemplate.repairs', [])
    },
    netTotal() {
      return this.parts.reduce((result, item) => {
        return result + item.unitPrice * item.quantity
      }, 0)
    },
    vatTotal() {
      return this.parts
        .filter((i) => i.vat === true)
        .reduce((res, item) => {
          return res + this.getVat(item.unitPrice * item.quantity)
        }, 0)
    },
    grandTotal() {
      return this.vatTotal + this.netTotal
    },
    photos() {
      return this.scanObject(this.booking, 'biddingTemplate.photos', [])
    },
  },
  mounted() {
    this.getBooking()
    this.getGarage()
  },
  methods: {
    submitBooking() {
      this.submitting = true
      delete this.booking.biddingTemplate.clusterType
      delete this.booking.biddingTemplate.assessorName
      axios
        .post(
          `/motor-assessment/api/garages/bidform/${this.booking.id}/${this.garageId}`,
          this.booking.biddingTemplate
        )
        .then((response) => {
          if (response.data.success) {
            this.downloadReport(
              'GARAGE_BIDDING_REPORT',
              this.garage.id,
              this.booking.id
            )
            this.submitting = false
            this.$store.commit('SNACKBAR', {
              state: 'success',
              message: 'Bid form submission successful',
              variant: 'success',
            })
            this.formSubmitted = true
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: response.data.msg,
              variant: 'error',
            })
            this.submitting = false
          }
        })
        .catch(() => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: 'AN Error Occurred, try again',
            variant: 'error',
          })
          this.loading = false
        })
    },
    getBooking() {
      axios
        .get(`/motor-assessment/api/booking/${this.bookingId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null)
            this.$router.push({ name: 'NotFound' })
          let resp = res.data.data
          let repairs = resp.biddingTemplate.repairs.map((i) => {
            return { ...i, ...{ unitPrice: '' } }
          })
          this.booking = {
            ...resp,
            ...{
              biddingTemplate: {
                ...resp.biddingTemplate,
                ...{ repairs: repairs },
              },
            },
          }
          this.loading = false
        })
    },
    getGarage() {
      axios
        .get(`/motor-assessment/api/garages/${this.garageId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null)
            this.$router.push({ name: 'NotFound' })
          this.garage = res.data.data
          this.loadingGarage = false
        })
    },
    getTotal(item) {
      let am = item.unitPrice * item.quantity
      if (item.vat) return am + this.getVat(am)
      return am
    },
    getVat(amount) {
      return (this.vatRate / 100) * amount
    },
  },
  filters: {
    formatCurrency,
    formatToHuman,
  },
}
</script>
