import axios from 'axios'

export default {
  data: () => ({
    assessmentReportLoading: false,
    garageReportLoading: false,
  }),
  methods: {
    downloadReport(reportType, garageId, bookingId) {
      if (reportType === 'GARAGE_BIDDING_REPORT')
        this.garageReportLoading = true
      else this.assessmentReportLoading = true
      let params =
        reportType === 'GARAGE_BIDDING_REPORT' ? `?garageId=${garageId}` : ''
      axios
        .get(
          `/motor-assessment/api/reports/assessment-report/${bookingId}/${reportType}${params}`,
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${reportType}.pdf`)
          document.body.appendChild(link)
          link.click()
          if (reportType === 'GARAGE_BIDDING_REPORT')
            this.garageReportLoading = false
          else this.assessmentReportLoading = false
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
          if (reportType === 'GARAGE_BIDDING_REPORT')
            this.garageReportLoading = false
          else this.assessmentReportLoading = false
        })
    },
  },
}
